.p-dialog {
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: 0 none;
  max-height: 85% !important;
  .p-dialog-header {
    border-bottom: 0 none;
    background: #ffffff;
    color: #343a40;
    padding: 2rem 2.5rem 1.25rem;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    .p-dialog-title {
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
    .p-dialog-header-icon {
      width: 2rem;
      height: 2rem;
      color: #6c757d;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      margin-right: 0.5rem;
      &:enabled {
        &:hover {
          color: #343a40;
          border-color: transparent;
          background: #e9ecef;
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0px 0px 0px 2px var(--brand-primary-bright);
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .p-dialog-content {
    background: #ffffff;
    color: #495057;
    padding: 1rem 2.5rem 2.5rem;
  }
  .p-dialog-footer {
    border-top: 0 none;
    background: #ffffff;
    color: #495057;
    padding: 0 2.5rem 2.5rem 2.5rem;
    text-align: right;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    button {
      margin: 0 0.5rem 0 0;
      width: auto;
    }
  }
}
.p-dialog.p-confirm-dialog {
  .p-confirm-dialog-icon {
    font-size: 2rem;
  }
  .p-confirm-dialog-message {
    margin-left: 1rem;
  }
}

.p-dialog-maximized {
  margin: 0 !important;
}

