.p-menubar {
  //padding: 1rem;
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
  //border: 1px solid #e5e5e5;
  border-radius: 4px;
  .p-menuitem-link {
    padding: 1rem 1rem;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0;
    transition: none;
    user-select: none;
    .p-menuitem-text {
      color: rgba(0, 0, 0, 0.87);
    }
    .p-menuitem-icon {
      color: rgba(0, 0, 0, 0.6);
      margin-right: 0.5rem;
    }
    .p-submenu-icon {
      color: rgba(0, 0, 0, 0.6);
    }
    &:not(.p-disabled) {
      &:hover {
        background: rgba(0, 0, 0, 0.04);
        .p-menuitem-text {
          color: rgba(0, 0, 0, 0.87);
        }
        .p-menuitem-icon {
          color: rgba(0, 0, 0, 0.6);
        }
        .p-submenu-icon {
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
    }
  }
  .p-menubar-root-list {
    >.p-menuitem {
      >.p-menuitem-link {
        padding: 1rem;
        color: rgba(0, 0, 0, 0.87);
        border-radius: 4px;
        transition: none;
        user-select: none;
        .p-menuitem-text {
          color: rgba(0, 0, 0, 0.87);
        }
        .p-menuitem-icon {
          color: rgba(0, 0, 0, 0.6);
          margin-right: 0.5rem;
        }
        .p-submenu-icon {
          color: rgba(0, 0, 0, 0.6);
          margin-left: 0.5rem;
        }
        &:not(.p-disabled) {
          &:hover {
            //background: rgba(0, 0, 0, 0.04);
            .p-menuitem-text {
              color: rgba(0, 0, 0, 0.87);
            }
            .p-menuitem-icon {
              color: rgba(0, 0, 0, 0.6);
            }
            .p-submenu-icon {
              color: rgba(0, 0, 0, 0.6);
            }
          }
        }
        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: none;
        }
      }
    }
    >.p-menuitem.p-menuitem-active {
      >.p-menuitem-link {
        //background: rgba(0, 0, 0, 0.04);
        &:not(.p-disabled) {
          &:hover {
            //background: rgba(0, 0, 0, 0.04);
            .p-menuitem-text {
              color: rgba(0, 0, 0, 0.87);
            }
            .p-menuitem-icon {
              color: rgba(0, 0, 0, 0.6);
            }
            .p-submenu-icon {
              color: rgba(0, 0, 0, 0.6);
            }
          }
        }
        .p-menuitem-text {
          color: rgba(0, 0, 0, 0.87);
        }
        .p-menuitem-icon {
          color: rgba(0, 0, 0, 0.6);
        }
        .p-submenu-icon {
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
  .p-submenu-list {
    padding: 0.5rem 0;
    background: #ffffff;
    border: 0 none;
    border-radius: 6px;
    //box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    width: 12.5rem;
    .p-menu-separator {
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      margin: 0.5rem 0;
    }
    .p-submenu-icon {
      font-size: 0.875rem;
    }
  }
  .p-menuitem.p-menuitem-active {
    >.p-menuitem-link {
      background: var(--grey-200);
      .p-menuitem-text {
        color: rgba(0, 0, 0, 0.87);
      }
      .p-menuitem-icon {
        color: rgba(0, 0, 0, 0.6);
      }
      .p-submenu-icon {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}
