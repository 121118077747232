.p-multiselect {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
  &:not(.p-disabled) {
    &:hover {
      border-color: var(--brand-primary);
    }
  }
  &:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem var(--brand-primary-bright);
    border-color: var(--brand-primary);
  }
  .p-multiselect-label {
    padding: 0.75rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-multiselect-label.p-placeholder {
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: #6c757d;
  }
  .p-multiselect-trigger {
    background: transparent;
    color: #6c757d;
    width: 3rem;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .p-multiselect-clear-icon {
    color: #6c757d;
    right: 3rem;
  }
}
.p-multiselect.p-multiselect-clearable {
  .p-multiselect-label {
    padding-right: 1.75rem;
  }
}
.p-multiselect.p-multiselect-chip {
  .p-multiselect-token {
    padding: 0.375rem 0.75rem;
    margin-right: 0.5rem;
    background: var(--bg-success);
    color: var(--text-selected-item-primary);
    border-radius: 6px;
    .p-multiselect-token-icon {
      margin-left: 0.5rem;
    }
  }
}
.p-multiselect.p-invalid.p-component {
  border-color: #e24c4c;
}
.p-inputwrapper-filled.p-multiselect.p-multiselect-chip {
  .p-multiselect-label {
    padding: 0.375rem 0.75rem;
  }
  .p-multiselect-label.p-multiselect-items-label {
    padding: 0.75rem 0.75rem;
  }
}
.p-inputwrapper-filled.p-multiselect.p-multiselect-clearable {
  .p-multiselect-label {
    padding-right: 1.75rem;
  }
}
.p-multiselect-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .p-multiselect-header {
    padding: 0.75rem 1.25rem;
    border-bottom: 1px solid #dee2e6;
    color: #343a40;
    background: #f8f9fa;
    margin: 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    .p-multiselect-filter-container {
      margin: 0 0.5rem;
      .p-inputtext {
        padding-right: 1.75rem;
      }
      .p-multiselect-filter-icon {
        right: 0.75rem;
        color: #6c757d;
      }
    }
    .p-multiselect-close {
      width: 2rem;
      height: 2rem;
      color: #6c757d;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      &:enabled {
        &:hover {
          color: #343a40;
          border-color: transparent;
          background: #e9ecef;
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem var(--brand-primary-bright);
      }
    }
  }
  .p-multiselect-items {
    padding: 0.75rem 0;
    .p-multiselect-item {
      margin: 0;
      padding: 0.75rem 1.25rem;
      border: 0 none;
      color: #495057;
      background: transparent;
      transition: box-shadow 0.2s;
      border-radius: 0;
      &:not(.p-highlight) {
        &:not(.p-disabled) {
          &:hover {
            color: #495057;
            background: #e9ecef;
          }
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 1px var(--brand-primary-bright);
      }
      .p-checkbox {
        margin-right: 0.5rem;
      }
    }
    .p-multiselect-item.p-highlight {
      color: var(--text-selected-item-primary);
      background: var(--brand-primary-light-40);
    }
    .p-multiselect-item-group {
      margin: 0;
      padding: 0.75rem 1.25rem;
      color: #343a40;
      background: #ffffff;
      font-weight: 700;
    }
    .p-multiselect-empty-message {
      padding: 0.75rem 1.25rem;
      color: #495057;
      background: transparent;
    }
  }
}
.p-input-filled {
  .p-multiselect {
    background: #e9ecef;
    &:not(.p-disabled) {
      &:hover {
        background-color: #e9ecef;
      }
    }
    &:not(.p-disabled).p-focus {
      background-color: #ffffff;
    }
  }
}
