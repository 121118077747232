.p-message {
  margin: 1rem 0;
  border-radius: 6px;
  font-size: 0.875rem;
  color: var(--grey-900);
}
.p-message .p-message-wrapper {
  padding: 1rem;
  display: flex;
  column-gap: 4px;
  align-items: center;
}
.p-message .p-message-close {
  align-self: flex-start;
  min-width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: transparent;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-message .p-message-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
.p-message .p-message-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem var(--brand-primary-bright);
}
.p-message.p-message-info {
  background: var(--grey-200);
}
.p-message.p-message-info .p-message-icon {
  color: var(----grey-700);
  align-self: flex-start;
}
.p-message.p-message-info .p-message-close {
  color: var(--text-secondary);
}
.p-message.p-message-success {
  background: #e4f8f0;
}
.p-message.p-message-success .p-message-icon {
  color: #1ea97c;
}
.p-message.p-message-success .p-message-close {
  color: var(--text-secondary);
}
.p-message.p-message-warn {
  background: var(--messages-warn);
}
.p-message.p-message-warn .p-message-icon {
  color: #4A2800;
}
.p-message.p-message-warn .p-message-close {
  color: var(--text-secondary);
}
.p-message.p-message-error {
  background: #ffe7e6;
}
.p-message.p-message-error .p-message-icon {
  color: #4A2800;
}
.p-message.p-message-error .p-message-close {
  color: var(--text-secondary);
}
.p-message .p-message-text {
  font-size: 1rem;
  font-weight: 400;
}
.p-message .p-message-icon {
  font-size: 1.22rem;
  margin-right: 1rem;
  color: var(--base-04) !important;
  line-height: 1.25rem;
}

.p-message-detail {
  margin: 0;
}
.p-message-summary {
  font-weight: 600;
  margin: 0;
}

.title-wrap {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
