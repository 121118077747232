.auth-page {
  width: 100%;
  height: 100%;
}

.q__logo{
  z-index: 1000;
  height: 54px;
  width: 200px;
  flex: 1;
}

.auth-block{
  height: 100%;
  background-color: var(--surface-0);
  display: flex;
  flex-direction: column;

  &>div{
    z-index: 1000;
    width: 100%;
  }

}

.auth-slider-section{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.md-plus-hidden{
  @media (max-width: 992px) {
    display:none;
  }
}

.bg-wrapper{
  clip-path: polygon(66% 0, 100% 0, 100% 100%, 33% 100%);
  background-color: var(--surface-50);
  height: 100%;
  width: 100%;
  position: absolute;
  top:0;
  left: 0;
  z-index: 10;
}

.auth-slider{
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  z-index: 1000;

  h3{
    font-weight: bold;
    font-size: 1.75rem;
    line-height: 2.125rem;
    margin: 0 0 0 0;
  }

  p{
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 2.5rem 0 1.5rem 0;
    min-height: 4.125rem;
    transition: all 500ms;
  }
  &__badges{
    display: flex;
    flex-direction: row;


    span{
      height: 1.125rem;
      width: 1.125rem;
      content:'';
      margin: 0 0.75rem 0 0;
      border-radius: 0.5rem;
      background: #E7E7E7; // пока нет константы
      transition: all 500ms;
    }

    .active{
      width: 4rem;
      background-color: var(--brand-primary);
    }
  }
}

.sign-in-form{
  width: 100%;
  flex: 2;

  &>div{
    padding: 0 0;
  }

  h1{
    margin: 0 0 40px 0;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  &__button-group{
    display: flex;
    flex-direction: row;
    margin: 2.5rem 0;

    span{
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 0.875rem;
    }

    a{
      margin: auto 0 auto 1.5rem;
      color: var(--text-selected-item-primary);
      font-weight: 600;
      font-size: 0.875rem;
    }
  }

  .inline-input{
    width: 100%;
  }

  &__form-group{
    margin: 1.5rem 0;
  }

  #login, #password{
    margin-top: 0.3rem;
  }

  #password > i{
    right: 0.75rem;;
  }
}





