.header-notification {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 36px;
    width: 36px;
    border-radius: 6px;
    &:hover{
      background-color: var(--bg-secondary);
    }
  }

  &__overlay-panel {
    max-height: 75vh;

    .p-overlaypanel-content {
      padding: 0 !important;
    }
  }

  &__header {
    border-bottom: 1px solid var(--divider-tabs);
    padding: 1.5rem 1.5rem 0 1.5rem;

    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;

      & > h2 {
        margin: 0;
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }

    &-panel {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
  }

  &__content {
    position: relative;
    min-height: 120px;
    max-height: calc(75vh - 130px);
    overflow: auto;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-bottom: 1rem;

    &-title {
      display: flex;
      align-items: center;
      margin-bottom: 0.75rem;

      &>h4 {
        margin: 0;
      }
    }

    &-wrapper {
      cursor: default;
      border-bottom: 1px solid var(--divider-tabs);
      padding: 1rem 1.5rem 1rem 1.5rem;
      //margin-bottom: 1.25rem;

      &:hover {
        background-color: var(--bg-secondary);
      }
    }

    &-body {
      line-height: 1.25rem;
      padding-left: 1rem;
      margin-bottom: 0.75rem;
    }

    &-date {
      margin: 0;
      margin-bottom: 1rem;
      padding-left: 1rem;
      font-size: 0.875rem;
      line-height: 1rem;
      color: var(--text-secondary);
    }
  }

  &__details-button {
    margin-left: 1rem !important;
  }

  &__danger {
    color: var(--text-invalid);
  }

  &__warning {
    color: var(--bg-orange);
  }

  &__yellow {
    color: #FFE603;
  }

  &__yellow-light {
    color: #FFFFA5;
  }

}
