@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes scalein {
  0% {
    opacity: 0;
    transform: scaleY(0.8);
    transition: transform .12s cubic-bezier(0, 0, 0.2, 1), opacity .12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

@keyframes slidedown {
  0% {
    max-height: 0;
  }
  100% {
    max-height: auto;
  }
}

@keyframes slideup {
  0% {
    max-height: 1000px;
  }
  100% {
    max-height: 0;
  }
}

@keyframes fadeinleft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
    transition: transform .12s cubic-bezier(0, 0, 0.2, 1), opacity .12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes fadeoutleft {
  0% {
    opacity: 1;
    transform: translateX(0%);
    transition: transform .12s cubic-bezier(0, 0, 0.2, 1), opacity .12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}

@keyframes fadeinright {
  0% {
    opacity: 0;
    transform: translateX(100%);
    transition: transform .12s cubic-bezier(0, 0, 0.2, 1), opacity .12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes fadeoutright {
  0% {
    opacity: 1;
    transform: translateX(0%);
    transition: transform .12s cubic-bezier(0, 0, 0.2, 1), opacity .12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes fadeinup {
  0% {
    opacity: 0;
    transform: translateY(-100%);
    transition: transform .12s cubic-bezier(0, 0, 0.2, 1), opacity .12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes fadeoutup {
  0% {
    opacity: 1;
    transform: translateY(0%);
    transition: transform .12s cubic-bezier(0, 0, 0.2, 1), opacity .12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

@keyframes fadeindown {
  0% {
    opacity: 0;
    transform: translateY(100%);
    transition: transform .12s cubic-bezier(0, 0, 0.2, 1), opacity .12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes fadeoutdown {
  0% {
    opacity: 1;
    transform: translateY(0%);
    transition: transform .12s cubic-bezier(0, 0, 0.2, 1), opacity .12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}

@keyframes animate-width {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.#{$prefix}fadein {
  animation: fadein .15s linear;
}

.#{$prefix}fadeout {
  animation: fadeout .15s linear;
}

.#{$prefix}slidedown {
  animation: slidedown .45s ease-in-out;
}

.#{$prefix}slideup {
  animation: slideup .45s cubic-bezier(0, 1, 0, 1);
}

.#{$prefix}scalein {
  animation: scalein .15s linear;
}

.#{$prefix}fadeinleft {
  animation: fadeinleft .15s linear;
}

.#{$prefix}fadeoutleft {
  animation: fadeoutleft .15s linear;
}

.#{$prefix}fadeinright {
  animation: fadeinright .15s linear;
}

.#{$prefix}fadeoutright {
  animation: fadeoutright .15s linear;
}

.#{$prefix}fadeinup {
  animation: fadeinup .15s linear;
}

.#{$prefix}fadeoutup {
  animation: fadeoutup .15s linear;
}

.#{$prefix}fadeindown {
  animation: fadeindown .15s linear;
}

.#{$prefix}fadeoutdown {
  animation: fadeoutdown .15s linear;
}

.#{$prefix}animate-width {
  animation: animate-width 1000ms linear;
}

$animation-duration-props: (
  'animation-duration-100': '100ms',
  'animation-duration-150': '150ms',
  'animation-duration-200': '200ms',
  'animation-duration-300': '300ms',
  'animation-duration-400': '400ms',
  'animation-duration-500': '500ms',
  'animation-duration-1000': '1000ms',
  'animation-duration-2000': '2000ms',
  'animation-duration-3000': '3000ms'
);

$animation-delay-props: (
  'animation-delay-100': '100ms',
  'animation-delay-150': '150ms',
  'animation-delay-200': '200ms',
  'animation-delay-300': '300ms',
  'animation-delay-400': '400ms',
  'animation-delay-500': '500ms',
  'animation-delay-1000': '1000ms'
);

$animation-iteration-props: (
  'animation-iteration-1': '1',
  'animation-iteration-2': '2',
  'animation-iteration-infinite': 'infinite'
);

$animation-timing-props: (
  'animation-linear': 'linear',
  'animation-ease-in': 'cubic-bezier(0.4, 0, 1, 1)',
  'animation-ease-out': 'cubic-bezier(0, 0, 0.2, 1)',
  'animation-ease-in-out': 'cubic-bezier(0.4, 0, 0.2, 1)'
);

$animation-fill-props: (
  'animation-fill-none': 'none',
  'animation-fill-forwards': 'forwards',
  'animation-fill-backwards': 'backwards',
  'animation-fill-both': 'both'
);

@include style-class('animation-duration', $animation-duration-props);
@include style-class('animation-delay', $animation-delay-props);
@include style-class('animation-iteration-count', $animation-iteration-props);
@include style-class('animation-timing-function', $animation-timing-props);
@include style-class('animation-fill-mode', $animation-fill-props);
