.p-tooltip {
  .p-tooltip-text {
    background: var(--base-04);
    color: #ffffff;
    padding: 0.75rem 0.75rem;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    font-size: 1rem;
    //line-height: 1.125rem;
  }
}
.p-tooltip.p-tooltip-right {
  .p-tooltip-arrow {
    border-right-color: var(--grey-700);
  }
}
.p-tooltip.p-tooltip-left {
  .p-tooltip-arrow {
    border-left-color: var(--grey-700);
  }
}
.p-tooltip.p-tooltip-top {
  .p-tooltip-arrow {
    border-top-color: var(--grey-700);
  }
}
.p-tooltip.p-tooltip-bottom {
  .p-tooltip-arrow {
    border-bottom-color: var(--grey-700);
  }
}
