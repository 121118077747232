.p-accordion {
  .p-accordion-header {
    .p-accordion-header-link {
      padding: 1rem;
      border: none;
      color: #6c757d;
      background: #FFFFFF;
      border-radius: 6px;
      transition: box-shadow 0.2s;
      .p-accordion-toggle-icon {
        margin-right: 0.5rem;
      }
    }
    &:not(.p-disabled) {
      .p-accordion-header-link {
        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0px 0px 0px 2px var(--brand-primary-bright);
        }
      }
    }
    &:not(.p-highlight) {
      &:not(.p-disabled) {
        &:hover {
          .p-accordion-header-link {
            color: #343a40;
          }
        }
      }
    }
    &:not(.p-disabled).p-highlight {
      .p-accordion-header-link {
        border-color: #dee2e6;
        color: #343a40;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
      &:hover {
        .p-accordion-header-link {
          color: #343a40;
        }
      }
    }
  }
  .p-accordion-content {
    padding: 1rem;
    background: #ffffff;
    color: #495057;
    font-size: 0.875rem;
  }
  .p-accordion-tab {
    margin-bottom: 4px;
  }
}
