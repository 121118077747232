.pi-sm {
  & > span:before {
    font-size: 0.75rem;
  }
}
.pi-md {
  & > span:before {
    font-size: 1rem;
  }
}
.pi-lg {
  & > span:before {
    font-size: 1.5rem;
  }
}
.pi-xlg {
  &:before {
    font-size: 2rem;
  }
}
