.p-inline-message {
  padding: 0.75rem 0.75rem;
  margin: 0;
  border-radius: 6px;
}
.p-inline-message.p-inline-message-info {
  background: var(--bg-hover-item);
  border: solid #696cff;
  border-width: 0px;
  color: var(--text-primary);
}
.p-inline-message.p-inline-message-info .p-inline-message-icon {
  color: var(--text-secondary);
}
.p-inline-message.p-inline-message-success {
  background: #e4f8f0;
  border: solid #1ea97c;
  border-width: 0px;
  color: #1ea97c;
}
.p-inline-message.p-inline-message-success .p-inline-message-icon {
  color: #1ea97c;
}
.p-inline-message.p-inline-message-warn {
  background: #fff2e2;
  border: solid #cc8925;
  border-width: 0px;
  color: #cc8925;
}
.p-inline-message.p-inline-message-warn .p-inline-message-icon {
  color: #cc8925;
}
.p-inline-message.p-inline-message-error {
  background: #ffe7e6;
  border: solid #ff5757;
  border-width: 0px;
  color: #ff5757;
}
.p-inline-message.p-inline-message-error .p-inline-message-icon {
  color: #ff5757;
}
.p-inline-message .p-inline-message-icon {
  font-size: 1rem;
  margin-right: 0.5rem;
}
.p-inline-message .p-inline-message-icon.p-icon {
  width: 1rem;
  height: 1rem;
}
.p-inline-message .p-inline-message-text {
  font-size: 1rem;
}
.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
  margin-right: 0;
}