.p-overlaypanel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 12px;
  //box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 6px 12px rgba(49, 63, 112, 0.05), 0px 6px 20px rgba(49, 63, 112, 0.1);
  .p-overlaypanel-content {
    padding: 1.25rem;
  }
  .p-overlaypanel-close {
    background: var(--brand-primary);
    color: #ffffff;
    width: 2rem;
    height: 2rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
    position: absolute;
    top: -1rem;
    right: -1rem;
    &:enabled {
      &:hover {
        background: var(--brand-primary);
        color: #ffffff;
      }
    }
  }
  &:after {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
  }
  &:before {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #f2f2f2;
  }
}
.p-overlaypanel.p-overlaypanel-flipped {
  &:after {
    border-top-color: #ffffff;
  }
  &:before {
    border-top-color: #ffffff;
  }
}
