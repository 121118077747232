.layout {
  &-wrapper {
    height: 100vh;
    width: 100vw;
  }

  &-main {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
  }

  &-container {
    width: 100%;
    height: calc(100vh - 60px);
    overflow: auto;
  }

  &-content{
    padding: 0 40px;
    max-width: 1920px;
    height: 100%;
    margin: 0 auto;
  }
}
