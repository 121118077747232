.toolbar-header{
  height: 3.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background-color: var(--surface-0);
  border: 1px solid var(--surface-300);
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
}
