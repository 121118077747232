.p-progressbar {
  border: 0 none;
  height: 1rem;
  background: var(--bg-hover-item);
  border-radius: 12px;
}
.p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: var(--brand-primary);
}
.p-progressbar .p-progressbar-label {
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
}
