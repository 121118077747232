.loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .p-progress-spinner-circle {
    stroke: var(--brand-primary);
    animation: p-progress-spinner-dash 1.5s ease-in-out infinite;
  }
}
