.search-with-icon{
  background: #FAFAFA;
  border-radius: 60px;
  padding: 0 !important;
  padding-right: 2rem;
  height: 34px;
  & input{
    background: #FAFAFA;
    border-color: transparent;
    &:hover{
      border-color: transparent;
    }
    &:focus{
      border-color: transparent;
    }
    &:focus-visible{
      border-color: transparent;
    }
    &:not(:focus){
      border-color: transparent;
    }
    &:active{
      border-color: transparent;
    }
  }
}
