.p-button {
  margin: 0;
  padding: 0.6875rem 1rem;
  border-radius: 6px;
  background-color: var(--brand-primary);
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--base-01);
  border: 1px solid var(--brand-primary);
  &:enabled:hover {
    background-color: var(--brand-primary-dark-40);
    border: 1px solid var(--brand-primary-dark-40);
  }

  &:focus-visible{
    box-shadow: none;
    outline:none;
  }
}

.p-button.p-button-outlined {
  padding: 0.6875rem 1rem;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  border: 1px solid var(--brand-primary);
  color: var(--brand-primary);
  background-color: transparent;
  &:enabled:hover {
    background-color: var(--bg-hover-item);
    border: 1px solid var(--brand-primary);
    color: var(--brand-primary);
  }
}

.p-button.p-button-secondary {
  color: var(--base-01);
  background: var(--text-secondary);
  border: 1px solid var(--text-secondary);
  &:enabled {
    &:hover {
      background: var(--text-secondary);
      color: var(--base-01);
      border-color: var(--text-secondary);
    }
    &:focus {
      box-shadow: 0 0 0 0.2rem #E2E8F0;
    }
    &:active {
      background: #334155;
      color: #ffffff;
      border-color: #334155;
    }
  }
  &:not(button) {
    &:not(a) {
      &:not(.p-disabled) {
        &:hover {
          background: var(--text-secondary);
          color: var(--base-01);
          border-color: var(--text-secondary);
        }
        &:focus {
          box-shadow: 0 0 0 0.2rem #E2E8F0;
        }
        &:active {
          background: #334155;
          color: #ffffff;
          border-color: #334155;
        }
      }
    }
  }
}

.p-button.p-button-secondary
{
  background-color: transparent;
  color: var(--text-secondary);
  border: 1px solid var(--text-secondary);
  &:enabled {
    &:hover {
      background: var(--bg-hover-item);
      color: #64748B;
      border: 1px solid;
    }
    &:active {
      background: var(--bg-selected-item);
      color: #64748B;
      border: 1px solid;
    }
  }
  &:not(button) {
    &:not(a) {
      &:not(.p-disabled) {
        &:hover {
          background: var(--bg-hover-item);
          color: #64748B;
          border: 1px solid;
        }
        &:active {
          background: var(--bg-selected-item);
          color: #64748B;
          border: 1px solid;
        }
      }
    }
  }
}

.p-button.p-button-text {
  background-color: transparent;
  color: var(--brand-primary);
  border-color: transparent;
  &:enabled {
    &:hover {
      background: rgba(5, 70, 121, 0.04);
      color: var(--brand-primary);
      border-color: transparent;
    }
    &:active {
      background: rgba(5, 70, 121, 0.16);
      color: var(--brand-primary);
      border-color: transparent;
    }
  }
  &:not(button) {
    &:not(a) {
      &:not(.p-disabled) {
        &:hover {
          background: rgba(5, 70, 121, 0.04);
          color: var(--brand-primary);
          border-color: transparent;
        }
        &:active {
          background: rgba(5, 70, 121, 0.16);
          color: var(--brand-primary);
          border-color: transparent;
        }
      }
    }
  }
}
.p-button.p-button-text.p-button-plain {
  color: #6c757d;
  &:enabled {
    &:hover {
      background: #e9ecef;
      color: #6c757d;
    }
    &:active {
      background: #dee2e6;
      color: #6c757d;
    }
  }
  &:not(button) {
    &:not(a) {
      &:not(.p-disabled) {
        &:hover {
          background: #e9ecef;
          color: #6c757d;
        }
        &:active {
          background: #dee2e6;
          color: #6c757d;
        }
      }
    }
  }
}

.p-button.p-button-secondary.p-button-text {
  background-color: transparent;
  color: var(--text-secondary);
  border-color: transparent;
  &:enabled {
    &:hover {
      background: var(--bg-hover-item);
      border-color: transparent;
      color: var(--text-secondary);
    }
    &:active {
      background: var(--bg-selected-item);
      border-color: transparent;
      color: var(--text-secondary);
    }
  }
  &:not(button) {
    &:not(a) {
      &:not(.p-disabled) {
        &:hover {
          background: var(--bg-hover-item);
          border-color: transparent;
          color: var(--text-secondary);
        }
        &:active {
          background: var(--bg-selected-item);
          border-color: transparent;
          color: var(--text-secondary);
        }
      }
    }
  }
}
.p-buttonset.p-button-secondary {
  >.p-button.p-button-text {
    background-color: transparent;
    color: var(--text-secondary);
    border-color: transparent;
    &:enabled {
      &:hover {
        background: var(--bg-hover-item);
        border-color: transparent;
        color: var(--text-secondary);
      }
      &:active {
        background: var(--bg-selected-item);
        border-color: transparent;
        color: var(--text-secondary);
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: var(--bg-hover-item);
            border-color: transparent;
            color: var(--text-secondary);
          }
          &:active {
            background: var(--bg-selected-item);
            border-color: transparent;
            color: var(--text-secondary);
          }
        }
      }
    }
  }
}
.p-splitbutton.p-button-secondary {
  >.p-button.p-button-text {
    background-color: transparent;
    color: var(--text-secondary);
    border-color: transparent;
    &:enabled {
      &:hover {
        background: var(--bg-hover-item);
        border-color: transparent;
        color: var(--text-secondary);
      }
      &:active {
        background: var(--bg-selected-item);
        border-color: transparent;
        color: var(--text-secondary);
      }
    }
    &:not(button) {
      &:not(a) {
        &:not(.p-disabled) {
          &:hover {
            background: var(--bg-hover-item);
            border-color: transparent;
            color: var(--text-secondary);
          }
          &:active {
            background: var(--bg-selected-item);
            border-color: transparent;
            color: var(--text-secondary);
          }
        }
      }
    }
  }
}
.p-fileupload-choose.p-button-secondary.p-button-text {
  background-color: transparent;
  color: var(--text-secondary);
  border-color: transparent;
  &:enabled {
    &:hover {
      background: var(--bg-hover-item);
      border-color: transparent;
      color: var(--text-secondary);
    }
    &:active {
      background: var(--bg-selected-item);
      border-color: transparent;
      color: var(--text-secondary);
    }
  }
  &:not(button) {
    &:not(a) {
      &:not(.p-disabled) {
        &:hover {
          background: var(--bg-hover-item);
          border-color: transparent;
          color: var(--text-secondary);
        }
        &:active {
          background: var(--bg-selected-item);
          border-color: transparent;
          color: var(--text-secondary);
        }
      }
    }
  }
}

.p-button.p-button-loading-label-only.p-button-loading-left .p-button-label {
  margin-left: 0.5rem;
}

.p-button .p-button-loading-icon {
  font-size: 12px;
}

.p-button.p-button-sm {
  font-size: 0.875rem;
  line-height: 17px;
  padding: 9.5px 0.75rem;
}

.p-button .p-button-icon-left  {
  font-size: 0.875rem;
  margin-right: 0.5rem;
}

.p-button .p-button-icon-right {
  font-size: 0.875rem;
  margin-left: 0.5rem;
}

.p-button-icon-only {
  width: 2.75rem;
  //height: 2.75rem;

  &.p-button-icon {
    font-size: 0.875rem;
  }
}

.p-button.p-button-rounded {
  border-radius: 2rem;
}
