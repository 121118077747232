.p-toast {
  opacity: 0.9;
  .p-toast-message {
    margin: 0 0 1rem 0;
    //box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    //border-radius: 6px;
    .p-toast-message-content {
      padding: 1rem;
      border-width: 0 0 0 6px;
      .p-toast-message-text {
        margin: 0 0 0 1rem;
      }
      .p-toast-message-icon {
        font-size: 1.25rem;
      }
      .p-toast-summary {
        font-weight: 700;
      }
      .p-toast-detail {
        margin: 0.25rem 0 0 0;
      }
    }
    .p-toast-icon-close {
      width: 2rem;
      height: 2rem;
      border-radius: 0%;
      background: transparent;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      &:hover {
        background: rgba(255, 255, 255, 0.3);
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #99F6E4;
      }
    }
  }
  .p-toast-message.p-toast-message-info {
    background: #e9e9ff;
    //border: solid #696cff;
    border-width: 0 0 0 6px;
    color: #696cff;
    .p-toast-message-icon {
      color: #696cff;
    }
    .p-toast-icon-close {
      color: #696cff;
    }
  }
  .p-toast-message.p-toast-message-success {
    background: #e4f8f0;
    //border: solid #1ea97c;
    border-width: 0 0 0 6px;
    color: #1ea97c;
    .p-toast-message-icon {
      color: #1ea97c;
    }
    .p-toast-icon-close {
      color: #1ea97c;
    }
  }
  .p-toast-message.p-toast-message-warn {
    background: #fff2e2;
    border: solid #cc8925;
    border-width: 0 0 0 6px;
    color: #cc8925;
    .p-toast-message-icon {
      color: #cc8925;
    }
    .p-toast-icon-close {
      color: #cc8925;
    }
  }
  .p-toast-message.p-toast-message-error {
    background: #ffe7e6;
    //border: solid #ff5757;
    border-width: 0 0 0 6px;
    color: #ff5757;
    .p-toast-message-icon {
      color: #ff5757;
    }
    .p-toast-icon-close {
      color: #ff5757;
    }
  }
}
