@import "./helpers/reset";
@import "./helpers/variables";
@import "./helpers/helpers";

@import "./atoms/icons";
@import "./atoms/logo-header";
@import "./atoms/paginator";
@import "./atoms/buttonRestyle";
@import "./atoms/gutterRestyle";

@import "./atoms/pRestyle";

@import "./helpers/components";
@import "./components/sidebar";
//@import "./components/dialogRestyle";
@import "./components/toolbar-header";
@import "./components/tab-menuRestyle";
@import "./components/search";
@import "./components/treeRestyle";
@import "./components/menubar";

@import "./atoms/inputtextRestyle";

@import "./components/topbarRestyle";
@import "./components/card";
@import "./components/datatable";
@import "./components/treetable";
@import "./components/paginatorRestyle";
@import "./components/menubarRestyle";
@import "./components/tooltipRestyle";
@import "./components/dropdownRestyle";
@import "./components/toastRestyle";
@import "./components/breadcrumbRestyle";
@import "./components/tabViewRestyle";
@import "./components/checkboxRestyle";
@import "./components/menuRestyle";
@import "./components/multiselectRestyle";
@import "./components/dialogRestyle";
@import "./components/accordionRestyle";
@import "./components/autocompleteRestyle";
@import "./components/badgeRestyle";
@import "./atoms/overlay";
@import "./components/messagesRestyle";
@import "./components/errorRestyle";
@import "./components/datepickerRestyle";
@import "./components/scrollpanelRestyle";
@import "./components/radiobuttonRestyle";
@import "./components/calendarRestyle";
@import "./components/inputSwitchRestyle";
@import "./components/inputNumber";
@import "./components/stepsRestyle";
@import "./components/panelRestyle";
@import "./components/confirmPopupRestyle";
@import "./components/tagRestyle";
@import "./components/selectButtonRestyle";
@import "./components/overlayPanelRestyle";
@import "components/tieredMenuRestyle";
@import "components/chipRestyle";
@import "components/progressbarRestyle";
@import "components/InputRangeRestyle";
@import "components/listboxRestyle";
@import "components/cascadeSelectRestyle";
@import "components/inlineMessageRestyle";

@import "./typography/typography";

@import "./media/media";

@import "./css/app/layout";

* {
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
}

.layout-main {
  //width: calc(100% - 66px - 1px); // 66px sidebar and 1 px border
}
