// topbar restyles

.layout-topbar {
  height: var(--topbar-height);
  background-color: var(--base-01);
  padding: 0 2.5rem;
  //box-shadow: 0 2px 5px rgb(0 0 0 / 2%), 0 0 2px rgb(0 0 0 / 5%), 0 1px 4px rgb(0 0 0 / 8%);
  border-bottom: 1px solid var(--base-03);
  color: var(--text-primary);

  .topbar-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--sidebar-min-width);
  }
}
