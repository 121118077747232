.sidebar {
  border-right: 1px solid var(--surface-300);

  a:not(:hover) {
    border: 1px solid transparent;
  }
  a:hover {
    background-color: var(--primary-50);
    color: #212121;
    border: 1px solid var(--primary-100);
  }
}

app-sidebar{
  max-width: 300px;
  min-width: 200px
}

