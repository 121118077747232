.error-boundary {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  &__container {
    margin: 0;
    text-align: center;

    div {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.25rem;
      color: var(--text-primary);
      margin-bottom: 1.25rem;
    }

    h1 {
      margin: 1.25rem 0 1rem ;
      font-weight: 700;
      font-size: 1.75rem;
      line-height: 2.125rem;
    }
  }

  &__animation {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1.25rem;
  }

  &__btn-group {
    .left{
      margin-right: 1rem;
    }
  }
}
