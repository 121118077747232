.p-chip {
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
  border-radius: 6px;
  padding: 0 0.375rem;
  .p-chip-text {
    font-size: 0.875rem;
    line-height: 17px;
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
  }
  .p-chip-icon {
    margin-right: 0.5rem;
  }
  img {
    width: 2.25rem;
    height: 2.25rem;
    margin-left: -0.75rem;
    margin-right: 0.5rem;
  }
  .p-chip-remove-icon {
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin-left: 0.5rem;
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem var(--brand-primary-bright);
    }
  }
}
