.p-badge {
  background: var(--brand-primary);
  color: #ffffff;
  font-size: 0.5rem;
  font-weight: 700;
  min-width: 0.75rem;
  height: 0.75rem;
  line-height: 0.75rem;
}
.p-badge.p-badge-secondary {
  background-color: #64748B;
  color: #ffffff;
}
.p-badge.p-badge-success {
  background-color: #22C55E;
  color: #ffffff;
}
.p-badge.p-badge-info {
  background-color: #3B82F6;
  color: #ffffff;
}
.p-badge.p-badge-warning {
  background-color: #F59E0B;
  color: #ffffff;
}
.p-badge.p-badge-danger {
  background-color: var(--text-invalid);
  color: #ffffff;
}
.p-badge.p-badge-lg {
  font-size: 1.125rem;
  min-width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
}
.p-badge.p-badge-xl {
  font-size: 1.5rem;
  min-width: 3rem;
  height: 3rem;
  line-height: 3rem;
}
.p-badge-dot {
  height: 0.5rem;
  min-width: 0.5rem;
  width: 0.5rem;
}
