.loading-overlay {
  &__panel-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 111;
    border-radius: 0.375rem;
    background-color: rgba(255, 255, 255, 0.7);
  }
}
