.p-selectbutton.p-buttonset {
  font-size: 1rem;

  .p-button{
    padding: 13px 0.75rem;

    color: var(--text-secondary);
    background-color: var(--surface-0);
    border: 1px solid var(--border-rest-input);

    &:hover {
      background-color: var(--border-rest-input);
      border-color: var(--grey-700);
    }
  }

  .p-button.p-highlight{
    color: var(--surface-0);
    background-color: var(--brand-primary);
    border: 1px solid var(--brand-primary);

      &:hover {
        background-color: var(--brand-primary-dark-40);
        border-color: var(--brand-primary-dark-40);
      }
  }
}
.p-buttonset .p-button:first-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.p-buttonset .p-button:last-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}