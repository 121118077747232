@media print {
  .no-print{
    display: none !important;
  }
  .print-full{
    width: 100vw !important;
  }
  .show-on-print{
    display:unset !important;
  }
}

$font-family: Segoe UI !important;
