.system-label {
  color: var(--surface-700);
  font-size: 0.875rem;
}

.system-header {
  font-weight: 600;
  font-size: 1rem;
  color: var(--surface-700);
}

.p-picklist-buttons {
  button {
    &:enabled:hover {
      background-color: var(--primary-700);
    }
    background-color: var(--primary-500);
  }
}

.p-inputtext {
  &:enabled {
    &:hover {
      border-color: var(--brand-primary);
    }
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0px 0px 0px 2px var(--brand-primary-bright);
      border-color: var(--brand-primary);
    }
  }
}

.custom {
  .p-scrollpanel-wrapper {
    border-right: 9px solid var(--section);
  }

  .p-scrollpanel-bar {
    background-color: var(--primary-100) !important;
    opacity: 1;
    transition: background-color 0.2s;

    &:hover {
      background-color: var(--primary-500) !important;
    }
  }
}

.no-header .p-dialog-header {
  padding: 0 !important;
}
.no-scroll .p-dialog-content {
  overflow-y: hidden !important;
}
.p-button {
  &:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem var(--brand-primary-bright);
  }
}

app-empty-page {
  height: 100vh;
}

.p-dropdown {
  display: inline-flex;
  position: relative;
  width: 100%;
  min-height: 44px;
}
.p-inputtext {
  width: 100%;
}
.p-autocomplete-panel {
  max-width: 100%;
  & li {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.p-picklist-list {
  height: 15rem;
}

.form {
  label{
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1rem;
    color: var(--text-secondary);
    &>.p-component {
      margin-top: 0.25rem; // отступ в 4 пикселя между лейблом и инпутом
    }
  }
  label:hover {
    cursor: pointer;
  }

  & .block {
    margin-top: 0.25rem;
    font-size: 12px;
  }

  .wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .box {
    width: 48%;
  }

  .box-32 {
    width: 32%;
  }
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

// scrollbar

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(108, 117, 125, 0.6);
  border-radius: 25px;
}

::-webkit-scrollbar-thumb:active {
  background-color: rgba(108, 117, 125);
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(108, 117, 125);
}

@supports (scrollbar-color: rgba(108, 117, 125, 0.6) transparent) {
  * {
    scrollbar-color: rgba(108, 117, 125, 0.6) transparent;
    scrollbar-width: thin;
  }
}
