.p-dropdown {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
  &:not(.p-disabled) {
    &:hover {
      border-color: var(--brand-primary);
    }
  }
  &:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0px 0px 0px 2px var(--brand-primary-bright);
    border-color: var(--brand-primary);
  }
  .p-dropdown-label {
    background: transparent;
    border: 0 none;
    line-height: 1.125rem;
    &:enabled {
      &:focus {
        outline: 0 none;
        box-shadow: none;
      }
    }
  }
  .p-dropdown-label.p-placeholder {
    color: #6c757d;
  }
  .p-dropdown-trigger {
    background: transparent;
    color: #6c757d;
    width: 3rem;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .p-dropdown-clear-icon {
    color: #6c757d;
    right: 3rem;
  }
}
.p-dropdown.p-dropdown-clearable {
  .p-dropdown-label {
    padding-right: 1.75rem;
  }
}
.p-dropdown.p-invalid.p-component {
  border-color: #e24c4c;
}
.p-dropdown-panel {
  background: #ffffff;
  color: var(--text-primary);
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .p-dropdown-header {
    padding: 0.75rem 1.25rem;
    border-bottom: 1px solid #dee2e6;
    color: #343a40;
    background: #f8f9fa;
    margin: 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    .p-dropdown-filter {
      padding-right: 1.75rem;
      margin-right: -1.75rem;
    }
    .p-dropdown-filter-icon {
      right: 0.75rem;
      color: #6c757d;
    }
    .p-dropdown-clearable-filter {
      .p-dropdown-filter {
        padding-right: 3.5rem;
        margin-right: -3.5rem;
      }
      .p-dropdown-filter-clear-icon {
        right: 2.5rem;
      }
    }
  }
  .p-dropdown-items {
    padding: 0.75rem 0;
    .p-dropdown-item {
      margin: 0;
      padding: 0.75rem 1.25rem;
      border: 0 none;
      color: var(--text-primary);
      font-size: 0.875rem;
      background: transparent;
      transition: box-shadow 0.2s;
      border-radius: 0;
      &:not(.p-highlight) {
        &:not(.p-disabled) {
          &:hover {
            color: var(--text-primary);
            background: #e9ecef;
          }
        }
      }
    }
    .p-dropdown-item.p-highlight {
      color: var(--text-selected-item-primary);
      background: var(--bg-selected-item);
    }
    .p-dropdown-item-group {
      margin: 0;
      padding: 0.75rem 1.25rem;
      color: #343a40;
      background: #ffffff;
      font-weight: 700;
    }
    .p-dropdown-empty-message {
      padding: 0.75rem 1.25rem;
      font-size: 0.875rem;
      color: var(--text-primary);
      background: transparent;
    }
  }
}
.p-input-filled {
  .p-dropdown {
    background: #e9ecef;
    &:not(.p-disabled) {
      &:hover {
        background-color: #e9ecef;
      }
    }
    &:not(.p-disabled).p-focus {
      background-color: #ffffff;
    }
  }
}
