.block-category-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.block-category {
    margin-bottom: 4rem;
}

.block-group {
    border-bottom: 1px solid var(--surface-d);
    margin-bottom: 2rem;

    &:last-child {
        border-bottom: 0;
    }
}

.block-group-title {
    font-weight: 700;
    padding: 1rem 2rem;
    display: block;
    background-color: var(--surface-a);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border:1px solid var(--surface-d);
}

.block-group-content {
    background-color: var(--surface-a);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border:1px solid var(--surface-d);
    border-top: 0 none;
    padding: 2rem;

    .grid {
        margin-right: -1rem;
        margin-left: -1rem;
        margin-top: -1rem;
    }

    .col-12 {
        padding: 1rem;
    }
}

.block-placeholder {
    width: 100%;
    display: block;
    margin-bottom: .75rem;
}

.block-name {
    display: block;
    font-weight: 700;
    margin-bottom: .5rem;
}

.block-count {
    display: block;
    color: var(--text-color-secondary);
}