/* Tree restyle for the sidebar, but for now general */
p-tree{
  & > div[class^="p-tree"]{
    border: none;
    padding: 0rem;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content{
    & .p-treenode-icon{
      color: var(--primary-500);
    }
    &:hover{
      .p-treenode-icon{
        color: var(--primary-700);
      }
    }
  }
}
