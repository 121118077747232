.font-size-1 {
  font-size: 0.5rem !important;
}
.font-size-2 {
  font-size: 0.75rem !important;
}
.font-size-3 {
  font-size: 1rem !important;
}
.font-size-4 {
  font-size: 1.5rem !important;
}
.font-size-5 {
  font-size: 2rem !important;
}
.font-size-6 {
  font-size: 2.5rem !important;
}
