.p-steps {
  .p-steps-item {
    .p-menuitem-link {
      transition: box-shadow 0.2s;
      border-radius: 6px;
      background: #ffffff;
      .p-steps-number {
        color: var(--text-secondary);
        border: 1px solid var(--border-rest-input);
        background: #ffffff;
        min-width: 1.75rem;
        height: 1.75rem;
        line-height: 1.25rem;
        font-size: 1rem;
        z-index: 1;
        border-radius: 50%;
      }
      .p-steps-title {
        font-size: 0.875rem;
        line-height: 1rem;
        margin-top: 0.375rem;
        color: var(--text-secondary);
      }
      &:not(.p-disabled) {
        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem transparent;
        }
      }
    }
    &:before {
      content: " ";
      border-top: 1px solid var(--divider-tabs);
      width: 100%;
      top: 50%;
      left: 0;
      display: block;
      position: absolute;
      margin-top: calc(-0.75rem + 1px);
    }
  }
  .p-steps-item.p-highlight {
    .p-steps-number {
      background: var(--brand-primary-light-40);
      color: var(--brand-primary);
      border: 1px solid var(--brand-primary);
    }
    .p-steps-title {
      font-weight: 400;
      color: var(--text-primary);
    }
  }
}
