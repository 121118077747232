.p-menu {
  padding: 0.25rem 0;
  background: #ffffff;
  color: var(--text-primary);
  border: 1px solid #dee2e6;
  border-radius: 6px;
  width: 12.5rem;
  .p-menuitem-link {
    padding: 0.75rem 1.25rem;
    color: var(--text-primary);
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
    .p-menuitem-text {
      color: var(--text-primary);
    }
    .p-menuitem-icon {
      color: var(--text-secondary);
      margin-right: 0.5rem;
    }
    .p-submenu-icon {
      color: var(--text-secondary);
    }
    &:not(.p-disabled) {
      &:hover {
        background: var(--bg-selected-item);
        .p-menuitem-text {
          color: var(--text-primary);
        }
        .p-menuitem-icon {
          color: var(--text-secondary);
        }
        .p-submenu-icon {
          color: var(--text-secondary);
        }
      }
    }
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 1px #99F6E4;
    }
  }
  .p-submenu-header {
    margin: 0;
    padding: 0.75rem 1.25rem;
    color: #343a40;
    background: #ffffff;
    font-weight: 700;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.25rem 0;
  }
}
.p-menu.p-menu-overlay {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
