.p-inputtext {
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: var(--text-secondary);
  background: #ffffff;
  padding: 0.75rem 0.75rem;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  appearance: none;
  border-radius: 6px;

  &:enabled {
    &:hover {
      border-color: var(--brand-primary);
    }
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0px 0px 0px 2px var(--brand-primary-bright);
      border-color: var(--brand-primary);
    }
  }
}
.p-inputtext.p-invalid.p-component {
  border-color: #e24c4c;
  box-shadow: none;
}

.p-inputtext.p-inputtext-sm {
  font-size: 0.875rem;
  padding: 0.65625rem 0.65625rem;
}

.p-inputtext.p-inputtext-lg {
  font-size: 1.25rem;
  padding: 0.9375rem 0.9375rem;
}
