.p-treetable {
  .p-paginator-top {
    border-width: 1px 0 1px 0;
    border-radius: 0;
  }
  .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-treetable-header {
    background: var(--surface-50);
    color: var(--surface-900);
    border: var(--surface-300);
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
  }
  .p-treetable-footer {
    background: var(--surface-50);
    color: var(--surface-900);
    border: var(--surface-300);
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
  }
  .p-treetable-thead {
    >tr {
      >th {
        text-align: left;
        padding: 1rem 0.5rem;
        border: 1px solid var(--surface-300);
        border-width: 0 0 1px 0;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.125rem;
        color: var(--surface-900);
        background: var(--surface-50);
        transition: box-shadow 0.2s;
      }
    }
  }
  .p-treetable-tfoot {
    >tr {
      >td {
        text-align: left;
        padding: 1rem 1rem;
        border: 1px solid var(--surface-300);
        border-width: 0 0 1px 0;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.125rem;
        color: var(--surface-900);
        background: var(--surface-50);
      }
    }
  }

  .p-sortable-column {
    .p-sortable-column-icon {
      color: var(--text-secondary);
      margin-left: 0.5rem;
    }
    .p-sortable-column-badge {
      border-radius: 50%;
      height: 1.143rem;
      min-width: 1.143rem;
      line-height: 1.143rem;
      color: rgba(255, 255, 255, 0.87);
      background: rgba(100, 181, 246, 0.16);
      margin-left: 0.5rem;
    }
    &:not(.p-highlight) {
      &:not(.p-sortable-disabled) {
        &:hover {
          background: rgba(255, 255, 255, 0.03);
          color: rgba(255, 255, 255, 0.87);
          .p-sortable-column-icon {
            color: rgba(255, 255, 255, 0.87);
          }
        }
      }
    }
    &:focus {
      box-shadow: inset 0 0 0 1px var(--primary-400);
      outline: 0 none;
    }
  }

  .p-sortable-column.p-highlight {
    background: #1e1e1e;
    color: var(--primary-500);
    .p-sortable-column-icon {
      color: var(--primary-500);
    }
    &:not(.p-sortable-disabled) {
      &:hover {
        background: rgba(255, 255, 255, 0.03);
        color: var(--primary-500);
        .p-sortable-column-icon {
          color: var(--primary-500);
        }
      }
    }
  }

  .p-treetable-tbody {
    >tr {
      background: var(--surface-0);
      color: var(--surface-900);
      font-size: 0.875rem;
      line-height: 1.125rem;
      transition: box-shadow 0.2s;
      outline-color: var(--primary-500);

      &:hover {
        background: var(--surface-100);
        //color: rgba(255, 255, 255, 0.87);
      }
      >td {
        text-align: left;
        border: 1px solid var(--surface-300);
        border-width: 0 0 1px 0;
        padding: 0.5rem 0.5rem;
        outline-color: var(--primary-500);

        .p-treetable-toggler {
          width: 1.5rem;
          height: 1.5rem;
          margin-right: 0.5rem;
          color: var(--surface-600);
          border: 0 none;
          background: transparent;
          border-radius: 50%;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
          &:enabled {
            &:hover {
              color: var(--surface-700);
              border-color: transparent;
              background: var(--surface-200);
            }
          }
          &:focus {
            outline: 0 none;
            outline-offset: 0;
            //box-shadow: 0 0 0 1px var(--surface-300);
          }
        }
        .p-row-editor-init {
          width: 2rem;
          height: 2rem;
          color: rgba(255, 255, 255, 0.6);
          border: 0 none;
          background: transparent;
          border-radius: 50%;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
          &:enabled {
            &:hover {
              color: rgba(255, 255, 255, 0.87);
              border-color: transparent;
              background: rgba(255, 255, 255, 0.03);
            }
          }
          &:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 1px var(--primary-400);;
          }
        }
        .p-row-editor-save {
          width: 2rem;
          height: 2rem;
          color: rgba(255, 255, 255, 0.6);
          border: 0 none;
          background: transparent;
          border-radius: 50%;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
          margin-right: 0.5rem;
          &:enabled {
            &:hover {
              color: rgba(255, 255, 255, 0.87);
              border-color: transparent;
              background: rgba(255, 255, 255, 0.03);
            }
          }
          &:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 1px var(--primary-600);;
          }
        }
        .p-row-editor-cancel {
          width: 2rem;
          height: 2rem;
          color: rgba(255, 255, 255, 0.6);
          border: 0 none;
          background: transparent;
          border-radius: 50%;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
          &:enabled {
            &:hover {
              color: rgba(255, 255, 255, 0.87);
              border-color: transparent;
              background: rgba(255, 255, 255, 0.03);
            }
          }
          &:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: 0 0 0 1px var(--primary-600);;
          }
        }
        >.p-column-title {
          font-weight: 600;
        }
      }
      >td.p-highlight {
        background: rgba(100, 181, 246, 0.16);
        color: rgba(255, 255, 255, 0.87);
      }
    }
    >tr.p-highlight {
      background: rgba(100, 181, 246, 0.16);
      color: rgba(255, 255, 255, 0.87);
    }
    >tr.p-treetable-dragpoint-top {
      >td {
        box-shadow: inset 0 2px 0 0 rgba(100, 181, 246, 0.16);
      }
    }
    >tr.p-treetable-dragpoint-bottom {
      >td {
        box-shadow: inset 0 -2px 0 0 rgba(100, 181, 246, 0.16);
      }
    }
  }
  .p-column-resizer-helper {
    background: var(--primary-400);;
  }
  .p-treetable-scrollable-header {
    background: #1e1e1e;
  }
  .p-treetable-scrollable-footer {
    background: #1e1e1e;
  }
  .p-treetable-loading-icon {
    font-size: 3rem;
    color: var(--brand-primary);
  }
}
.p-treetable.p-treetable-gridlines {
  .p-treetable-header {
    border-width: 1px 1px 0 1px;
  }
  .p-treetable-footer {
    border-width: 0 1px 1px 1px;
  }
  .p-paginator-top {
    border-width: 0 1px 0 1px;
  }
  .p-paginator-bottom {
    border-width: 0 1px 1px 1px;
  }
  .p-treetable-thead {
    >tr {
      >th {
        border-width: 1px 1px 1px 1px;
      }
    }
  }
  .p-treetable-tbody {
    >tr {
      >td {
        border-width: 1px;
      }
    }
  }
  .p-treetable-tfoot {
    >tr {
      >td {
        border-width: 1px;
      }
    }
  }
}
.p-treetable.p-treetable-striped {
  .p-treetable-tbody {
    >tr.p-row-odd {
      background: rgba(255, 255, 255, 0.01);
      + {
        .p-row-expanded {
          background: rgba(255, 255, 255, 0.01);
        }
      }
    }
    >tr.p-row-odd.p-highlight {
      background: rgba(100, 181, 246, 0.16);
      color: rgba(255, 255, 255, 0.87);
      .p-treetable-toggler {
        color: rgba(255, 255, 255, 0.87);
        &:hover {
          color: rgba(255, 255, 255, 0.87);
        }
      }
    }
  }
}
.p-treetable.p-treetable-sm {
  .p-treetable-header {
    padding: 0.5rem 0.5rem;
  }
  .p-treetable-thead {
    >tr {
      >th {
        padding: 0.5rem 0.5rem;
      }
    }
  }
  .p-treetable-tbody {
    >tr {
      >td {
        padding: 0.5rem 0.5rem;
      }
    }
  }
  .p-treetable-tfoot {
    >tr {
      >td {
        padding: 0.5rem 0.5rem;
      }
    }
  }
  .p-treetable-footer {
    padding: 0.5rem 0.5rem;
  }
}
.p-treetable.p-treetable-lg {
  .p-treetable-header {
    padding: 1.25rem 1.25rem;
  }
  .p-treetable-thead {
    >tr {
      >th {
        padding: 1.25rem 1.25rem;
      }
    }
  }
  .p-treetable-tbody {
    >tr {
      >td {
        padding: 1.25rem 1.25rem;
      }
    }
  }
  .p-treetable-tfoot {
    >tr {
      >td {
        padding: 1.25rem 1.25rem;
      }
    }
  }
  .p-treetable-footer {
    padding: 1.25rem 1.25rem;
  }
}
