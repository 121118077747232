.p-inputnumber.p-invalid.p-component > .p-inputtext {
  border-color: var(--brand-danger);
}

.p-inputnumber-buttons-stacked .p-inputnumber-button-group {
  width: 44px;

  &>.p-button-icon-only {
    //height: auto;
    //width: auto;

    padding: 0;
  }
}

.p-inputnumber-button-up {
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.p-inputnumber-button-down {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.p-inputnumber-input {
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

.p-inputnumber-button-group .p-button {
  background-color: transparent;
  border: 1px solid #ced4da;
  border-left: none;

  .p-button-icon {
    color: var(--text-secondary);
  }

  &:enabled:hover {
    background-color: transparent;
  }
}
