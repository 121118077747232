.p-datepicker {
  padding: 0.5rem;
  background: #ffffff;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 6px;
  &:not(.p-datepicker-inline) {
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    .p-datepicker-header {
      background: #ffffff;
    }
  }
  .p-datepicker-header {
    padding: 0.5rem;
    color: #495057;
    background: #ffffff;
    font-weight: 600;
    margin: 0;
    border-bottom: 1px solid #dee2e6;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    .p-datepicker-prev {
      width: 2rem;
      height: 2rem;
      color: #6c757d;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      &:enabled {
        &:hover {
          color: #343a40;
          border-color: transparent;
          background: #e9ecef;
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem var(--brand-primary-bright);
      }
    }
    .p-datepicker-next {
      width: 2rem;
      height: 2rem;
      color: #6c757d;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      &:enabled {
        &:hover {
          color: #343a40;
          border-color: transparent;
          background: #e9ecef;
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem var(--brand-primary-bright);
      }
    }
    .p-datepicker-title {
      line-height: 2rem;
      select {
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem var(--brand-primary-bright);
          border-color: var(--brand-primary);
        }
      }
      .p-datepicker-month {
        margin-right: 0.5rem;
      }
    }
  }
  table {
    font-size: 1rem;
    margin: 0.5rem 0;
    th {
      padding: 0.5rem;
      >span {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
    td {
      padding: 0.5rem;
      >span {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        transition: box-shadow 0.2s;
        border: 1px solid transparent;
        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem var(--brand-primary-bright);
        }
      }
      >span.p-highlight {
        color: var(--text-selected-item-primary);
        background: #F0FDFA;
      }
    }
    td.p-datepicker-today {
      >span {
        background: #ced4da;
        color: #495057;
        border-color: transparent;
      }
      >span.p-highlight {
        color: var(--text-selected-item-primary);
        background: #F0FDFA;
      }
    }
  }
  .p-datepicker-buttonbar {
    padding: 1rem 0;
    border-top: 1px solid #dee2e6;
    .p-button {
      width: auto;
    }
  }
  .p-timepicker {
    border-top: 1px solid #dee2e6;
    padding: 0.5rem;
    button {
      width: 2rem;
      height: 2rem;
      color: #6c757d;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      &:enabled {
        &:hover {
          color: #343a40;
          border-color: transparent;
          background: #e9ecef;
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem var(--brand-primary-bright);
      }
      &:last-child {
        margin-top: 0.2em;
      }
    }
    span {
      font-size: 1.25rem;
    }
    >div {
      padding: 0 0.5rem;
    }
  }
  .p-monthpicker {
    margin: 0.5rem 0;
    .p-monthpicker-month {
      padding: 0.5rem;
      transition: box-shadow 0.2s;
      border-radius: 6px;
    }
    .p-monthpicker-month.p-highlight {
      color: var(--text-selected-item-primary);
      background: #F0FDFA;
    }
  }
  &:not(.p-disabled) {
    table {
      td {
        span {
          &:not(.p-highlight) {
            &:not(.p-disabled) {
              &:hover {
                background: #e9ecef;
              }
              &:focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: 0 0 0 0.2rem var(--brand-primary-bright);
              }
            }
          }
        }
      }
    }
    .p-monthpicker {
      .p-monthpicker-month {
        &:not(.p-highlight) {
          &:not(.p-disabled) {
            &:hover {
              background: #e9ecef;
            }
            &:focus {
              outline: 0 none;
              outline-offset: 0;
              box-shadow: 0 0 0 0.2rem var(--brand-primary-bright);
            }
          }
        }
      }
    }
  }
}
.p-datepicker.p-datepicker-timeonly {
  .p-timepicker {
    border-top: 0 none;
  }
}
.p-datepicker.p-datepicker-multiple-month {
  .p-datepicker-group {
    border-right: 1px solid #dee2e6;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    padding-top: 0;
    padding-bottom: 0;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      border-right: 0 none;
    }
  }
}
@media screen and (max-width: 769px) {
  .p-datepicker {
    table {
      th {
        padding: 0;
      }
      td {
        padding: 0;
      }
    }
  }
}
