.p-breadcrumb {
  background: var(--base-01);
  //border: 1px solid #dee2e6;
  border-radius: 6px;
  //padding: 1rem;
  font-size: 0.875rem;

  ul {
    li {
      .p-menuitem-link {
        transition: box-shadow 0.2s;
        border-radius: 6px;
        &:focus {
          outline: 0 none;
          outline-offset: 0;
          //box-shadow: 0 0 0 0.2rem #99F6E4;
        }
        .p-menuitem-text {
          color: var(--text-secondary);
        }
        .p-menuitem-icon {
          color: var(--text-secondary);
        }
      }
      &:last-child {
        .p-menuitem-text {
          opacity: 0.6;
        }
        .p-menuitem-icon {
          opacity: 0.6;
        }
      }
    }
    li.p-breadcrumb-chevron {
      margin: 0 0.5rem 0 0.5rem;
      color: var(--text-secondary);
    }
  }
}
