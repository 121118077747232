@font-face {
  font-family: 'dd_icons';
  src:  url('dd-icons.eot?mjdta1');
  src:  url('dd-icons.eot?mjdta1#iefix') format('embedded-opentype'),
    url('dd-icons.ttf?mjdta1') format('truetype'),
    url('dd-icons.woff?mjdta1') format('woff'),
    url('dd-icons.svg?mjdta1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'dd_icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-function:before {
  content: "\e90a";
  color: #6c757d;
}
.icon-clipboard-edit-menu:before {
  content: "\e902";
  color: #6c757d;
}
.icon-question-circle-menu:before {
  content: "\e906";
  color: #6c757d;
}
.icon-book-menu:before {
  content: "\e909";
  color: #6c757d;
}
.icon-clipboard-arr-menu:before {
  content: "\e90f";
  color: #6c757d;
}
.icon-chart-arr-menu:before {
  content: "\e900";
  color: #6c757d;
}
.icon-inclinometry-menu:before {
  content: "\e901";
  color: #6c757d;
}
.icon-file-menu:before {
  content: "\e903";
  color: #6c757d;
}
.icon-function-menu:before {
  content: "\e904";
  color: #6c757d;
}
.icon-home-menu:before {
  content: "\e905";
  color: #6c757d;
}
.icon-settings-menu:before {
  content: "\e907";
  color: #6c757d;
}
.icon-sidebar-menu:before {
  content: "\e908";
  color: #6c757d;
}
.icon-chart-menu:before {
  content: "\e90b";
  color: #6c757d;
}
.icon-check-circle-menu:before {
  content: "\e90c";
  color: #6c757d;
}
.icon-clipboard-filled-menu:before {
  content: "\e90d";
  color: #6c757d;
}
.icon-closed-sidebar-menu:before {
  content: "\e90e";
  color: #6c757d;
}
.icon-pass-menu:before {
  content: "\e910";
  color: #6c757d;
}
.icon-well-menu:before {
  content: "\e911";
  color: #6c757d;
}
