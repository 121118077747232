//user-menu in header
.p-menubar {
  color: var(--surface-0);
  border-radius: 3px;
  .p-menuitem-link {
    padding: 0.75rem 1rem !important;
    color: var(--surface-0);
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
    .p-menuitem-text {
      color: var(--surface-0);
    }
    .p-menuitem-icon {
      color: var(--surface-0);
      margin-right: 0.5rem;
    }
    .p-submenu-icon {
      color: var(--surface-0);
    }
    &:not(.p-disabled) {
      &:hover {
        background: rgba(255, 255, 255, 0.03);
        .p-menuitem-text {
          color: var(--surface-0);
        }
        .p-menuitem-icon {
          color: var(--surface-0);
        }
        .p-submenu-icon {
          color: var(--surface-0);
        }
      }
    }
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 1px var(--surface-800);
    }
  }
  .p-menubar-root-list {
    >.p-menuitem {
      >.p-menuitem-link {
        padding: 0.75rem 1rem !important;
        color: var(--surface-0);
        border-radius: 3px;
        transition: box-shadow 0.2s;
        user-select: none;
        .p-menuitem-text {
          color: var(--surface-0);
        }
        .p-menuitem-icon {
          color: var(--surface-0);
          margin-right: 0.5rem;
        }
        .p-submenu-icon {
          color: var(--surface-0);
          margin-left: 0.5rem;
        }
        &:not(.p-disabled) {
          &:hover {
            background: rgba(255, 255, 255, 0.03);
            .p-menuitem-text {
              color: var(--surface-0);
            }
            .p-menuitem-icon {
              color: var(--surface-0);
            }
            .p-submenu-icon {
              color: var(--surface-0);
            }
          }
        }
        &:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: inset 0 0 0 1px var(--surface-900);
        }
      }
    }
    >.p-menuitem.p-menuitem-active {
      >.p-menuitem-link {
        background: rgba(255, 255, 255, 0.03);
        &:not(.p-disabled) {
          &:hover {
            background: rgba(255, 255, 255, 0.03);
            .p-menuitem-text {
              color: var(--surface-0);
            }
            .p-menuitem-icon {
              color: var(--surface-0);
            }
            .p-submenu-icon {
              color: var(--surface-0);
            }
          }
        }
        .p-menuitem-text {
          color: var(--surface-0);
        }
        .p-menuitem-icon {
          color: var(--surface-0);
        }
        .p-submenu-icon {
          color: var(--surface-0);
        }
      }
    }
  }
  .p-submenu-list {
    padding: 0.25rem 0;
    background: #1e1e1e;
    border: 1px solid #383838;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    width: 12.5rem;
    .p-menu-separator {
      border-top: 1px solid #383838;
      margin: 0.25rem 0;
    }
    .p-submenu-icon {
      font-size: 0.875rem;
    }
  }
  .p-menuitem.p-menuitem-active {
    >.p-menuitem-link {
      background: rgba(255, 255, 255, 0.03);
      .p-menuitem-text {
        color: var(--surface-0);
      }
      .p-menuitem-icon {
        color: var(--surface-0);
      }
      .p-submenu-icon {
        color: var(--surface-0);
      }
    }
  }
}
