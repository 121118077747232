.d-none{
  display: none;
}
.v-hidden{
  visibility: hidden;
}
.p-text-truncate{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.p-text-truncate-children [class*=-label]{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
