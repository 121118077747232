.p-tag {
  background: #CAE6FC;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 15px;
  color: #313F70;
  padding: 2px 8px 4px 8px;
  border-radius: 12px;
}

.p-tag-icon, .p-tag-icon.pi, .p-tag-value {
  line-height: 1.5;
}

.p-tag.p-tag-success {
  background-color: var(--bg-success);
  color: var(--text-selected-item-primary);
}

.p-tag p-tag-danger {
  background-color: var(--messages-error);
  color: var(--red-900);
}

.p-tag.p-tag-warning {
  background-color: var(--messages-warn);
  color: #A15A00;
}

.p-tag.p-tag-info {
  background-color: #E9ECEF;
  color: #313F70;
}

.p-tag.p-tag-icon {
  margin-right: 0.25rem;
  font-size: 0.75rem;
}