.p-paginator {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: solid #e4e4e4;
  border-width: 0;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: transparent;
  border: 0 none;
  color: rgba(0, 0, 0, 0.6);
  min-width: 3rem;
  height: 3rem;
  margin: 0.143rem;
  transition: none;
  border-radius: 50%;
}
.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: rgba(0, 0, 0, 0.04);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.6);
}
.p-paginator .p-paginator-first {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.p-paginator .p-paginator-last {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
.p-paginator .p-dropdown {
  margin-left: 0.5rem;
  //height: 3rem;
}
.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
}
.p-paginator .p-paginator-page-input {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.p-paginator .p-paginator-page-input .p-inputtext {
  max-width: 3rem;
}
.p-paginator .p-paginator-current {
  background-color: transparent;
  border: 0 none;
  color: rgba(0, 0, 0, 0.6);
  min-width: 3rem;
  height: 3rem;
  margin: 0.143rem;
  padding: 0 0.5rem;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: transparent;
  border: 0 none;
  color: rgba(0, 0, 0, 0.6);
  min-width: 3rem;
  height: 3rem;
  margin: 0.143rem;
  transition: none;
  border-radius: 50%;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: var(--bg-selected-item);
  border-color: var(--bg-selected-item);
  color: var(--text-selected-item-primary);
}
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: rgba(0, 0, 0, 0.04);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.6);
}
