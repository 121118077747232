.p-menuitem-link{
  //padding: 1rem 0 !important;
  border-color: white !important;
}
.p-tabmenuitem{
  padding: 0 1rem !important;

  & .p-highlight{
    color: #30c28d !important;
    border-color: #30c28d !important;
  }
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link{
  background: #ffffff;
  border-color: #6c757d;
  color: var(--green-800);
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: #ffffff;
  border-color: #6c757d;
  color: #30c28d !important;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem transparent;
}

.p-tabmenu .p-tabmenu-nav{
  border: 2px solid white !important;
}
