.p-autocomplete {
  .p-autocomplete-loader {
    right: 0.75rem;
  }
  .p-autocomplete-multiple-container {
    padding: 0.375rem 0.75rem;
    &:not(.p-disabled) {
      &:hover {
        border-color: var(--brand-primary);
      }
    }
    &:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0px 0px 0px 2px var(--brand-primary-bright);
      border-color: var(--brand-primary);
    }
    .p-autocomplete-input-token {
      padding: 0.375rem 0;
      input {
        font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-size: 1rem;
        color: #495057;
        padding: 0;
        margin: 0;
      }
    }
    .p-autocomplete-token {
      padding: 0.375rem 0.75rem;
      margin-right: 0.5rem;
      background: #F0FDFA;
      color: var(--text-selected-item-primary);
      border-radius: 6px;
      .p-autocomplete-token-icon {
        margin-left: 0.5rem;
      }
    }
  }
}
.p-autocomplete.p-autocomplete-dd {
  .p-autocomplete-loader {
    right: 3.75rem;
  }
}
.p-autocomplete.p-invalid.p-component {
  >.p-inputtext {
    border-color: #e24c4c;
  }
}
.p-autocomplete-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .p-autocomplete-items {
    padding: 0.75rem 0;
    .p-autocomplete-item {
      margin: 0;
      padding: 0.75rem 1.25rem;
      border: 0 none;
      color: #495057;
      background: transparent;
      transition: box-shadow 0.2s;
      border-radius: 0;
      &:hover {
        color: #495057;
        background: #e9ecef;
      }
    }
    .p-autocomplete-item.p-highlight {
      color: var(--text-selected-item-primary);
      background: #F0FDFA;
    }
    .p-autocomplete-item-group {
      margin: 0;
      padding: 0.75rem 1.25rem;
      color: #343a40;
      background: #ffffff;
      font-weight: 700;
    }
  }
}

.p-autocomplete-dd-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 0;
}
.p-autocomplete-dropdown {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}