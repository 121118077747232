.header {
  &__search-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__favorite-search {
    width: 260px;
    height: 38px;
    font-size: 0.75rem;

    &-wrapper {
      position: relative;
      margin-right: 1.5rem;

      & > i {
        z-index: 1;
        position: absolute;
        left: 0.75rem;
        top: 0.75rem;
        font-size: 0.875rem;
        color: var(--bg-orange);
      }
    }

    & > input {
      padding-left: 2rem;
    }

    & > button {
      background-color: #fff;
      border: 1px solid var(--base-03);
      color: var(--base-03);
      width: 38px;
      height: 38px;
    }
  }

  &__well-search {
    width: 360px;
    height: 38px;
    font-size: 0.875rem;
    color: var(--text-primary);

    &-wrapper {
      position: relative;

      & > i {
        z-index: 1;
        position: absolute;
        left: 0.7rem;
        top: 0.7rem;
        color: var(--base-04);
      }
    }

    & > input {
      padding-left: 2rem;
      border-color: transparent;
      background-color: var(--bg-secondary);
    }

    &-autocomplete {
      li.p-autocomplete-item {
        padding: 0 !important;
      }
    }

    &-list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 20px;
    }

    &-submenu {
      padding: 0.75rem 0 !important;
      min-width: 320px;
      // todo изменять на узких экранах
      transform: translate(350px, -56px) !important;

      .p-menuitem-text {
        margin-left: 0.5rem;
        font-size: 1rem;
        line-height: 1.25rem !important;
      }
    }

    &-icon {
      color: var(--text-secondary);
      font-size: 0.875rem;
    }
  }

  &__right-wrapper {
    display: flex;
    align-items: center;
    justify-content: end;
  }

  &__username-wrapper {
    margin-right: 1.5rem;

    button {
      height: 38px;
      & > .p-button-label {
        font-size: 1rem;
        line-height: 1rem;
        color: var(--text-primary);
      }

      .p-button-icon {
        margin-left: 0.5rem;
        font-size: 0.875rem;
      }
    }
  }

  &__dropdown-fields {

    i {
      color: var(--bg-orange);
      margin-right: 0.875rem;
    }
  }
}
