.side-menu {
  padding: 0.75rem 0 0.75rem 0;
  // height: 100vh;
  width: var(--sidebar-min-width);
  background-color: var(--base-01);
  border-right: 1px solid var(--base-03);
  list-style-type: none;
  transition: width .2s;

  .sidebar-header{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 2.375rem;

    img{
      height: 2rem;
      width: 1rem;
    }

    h2{
      font-size: 1rem;
      margin: 0 0.5rem;
    }
  }

  .p-scrollpanel-content{
    list-style-type: none;
    padding: 0 0.75rem ;
    padding-top: 4px ;
  }

  .p-scrollpanel{
    margin: 1.5rem 0 ;
    height: calc(100vh - 300px);
  }

  ul{
    list-style-type: none;
    margin: 1.5rem 0;
    padding: 0 0.75rem;
    // height: calc(100vh - 300px);
  }
  &__qwe{
    //height: calc(100vh - 192px);
  }

  &__scroll-list{
    padding: 0 0 0 0.75rem !important;
    padding-top: 4px !important;
    overflow-y: scroll;
  }

  &__scroll-list-minimized{
    padding-top: 4px !important;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: none; // Chrome, Safari and Opera
    }

    -ms-overflow-style: none; //IE, Edge

    @supports (scrollbar-width: none) {
      scrollbar-width: none; // firefox
    }
  }

  &__bordered{
    position: relative !important;
    line-height: 2;
    padding-top: 0.5rem !important;
    margin-top: 1.5rem;

    &::before{
      content: '';
      position: absolute;
      background-color: var(--base-03);
      height: 1px;
      width: 66px;

      top: 0 !important;
      left:-12px !important;
    }
  }

  &__item {
    margin-bottom: 0.5rem;

    &:focus-visible, &:focus{
      outline:none;
    }

    a:focus-visible{
      outline:none;
      box-shadow: 0 0 0 2px var(--brand-primary-bright);
    }

    a:focus{
      box-shadow: 0 0 0 2px var(--brand-primary-bright);
    }

    a {
      display: flex;
      justify-content: start;
      align-items: center;
      text-decoration: none;
      color: var(--text-primary);
      font-weight: 400;
      height: 36px;
      border-radius: 6px;
      cursor: pointer;

      &:hover{
        background-color: var(--grey-110) !important;
        border: 1px solid var(--grey-110) !important;
        border-radius: 6px;
      }

      .menuitem-toggle-icon{
        margin-left: auto;
      }
    }

    span {
      font-size: 0.875rem;
      line-height: 1.125rem;
      white-space: nowrap;
    }

    &>span{
      font-size: 0.75rem;
      margin-left: 0.5rem;

      &:before{
        color:var(--text-secondary);
      }
    }
  }

  &__small-submenu{
    left: 66px !important;
    transform: translateY(-42px) !important;
    box-shadow: #4547a9;
  }

  &__title{
    margin-top: 1.5rem;
    color: var(--text-secondary);
  }

  &__active{
    color: var(--brand-primary-dark-50);
    background-color: var(--bg-selected-item);
    border-radius: 6px;

    i, span:before {
      color: var(--brand-primary-dark-50);
    }
  }

  &__active-parent a{
    color: var(--brand-primary-dark-50);
    background-color: var(--bg-selected-item);
    border-radius: 6px;
    margin: 2px 0;

    i, span:before {
      color: var(--brand-primary-dark-50);
    }
  }

  &__switcher {
    display: flex;
    align-items: center;
    margin: 0 0.75rem;
    cursor: pointer;
    min-height: 36px;
    color: var(--text-primary);
    border: 1px solid transparent;

    &:hover {
      background-color: var(--grey-110);
      border: 1px solid var(--grey-110);
      border-radius: 6px;
    }

    i {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.5rem;
      height: 1.5rem;
      margin: 7px;
    }

    span {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.125;
    }

    .icon-sidebar:before{
      font-size: 0.925rem;
    }
  }

  &__menu-icon {
    width: 1rem;
    margin: 0 0.5rem;

    &:before {
      font-size: 1rem;

    }
  }

  &__menu-divider{
    color: var(--surface-300);
    align-self: stretch;
  }

  .menu-icon-color{
    color: var(--text-secondary);
  }

  &__submenu{
    overflow: hidden;
    margin:  0.5rem 0 0 1.5rem !important;
    padding: 0 !important;


    .side-menu__menu-icon{
      display: none;
    }

    li:focus-visible, li:focus{
      box-shadow: none;
    }

    a{
      padding:  0 0.75rem !important;
      border: 2px solid transparent !important;
    }

    a:hover{
      border: 2px solid transparent !important;
    }

    a:focus-visible, a:focus{
      box-shadow: none;
      outline:none;
      border: 2px solid var(--brand-primary-bright) !important;
    }
  }

  &__small-item{
    position: relative;
  }
  &__small-item:after{
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    height: 0.5rem;
    width: 0.5rem;
    background-color: #CED4DA;
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  }

  //AppMenu height
  &__bottom{
    overflow-y: hidden;
    height: 105px;
  }

  &__main{
    height: calc(100vh - 195px);
  }
}



.popupLink{
  display: flex;
  justify-content: start;
  align-items: center;
  text-decoration: none;
  color: var(--text-primary);
  font-weight: 400;
  min-height: 36px;
  cursor: pointer;
  padding: 0.75rem 1.25rem;
  border: 2px solid transparent;

  &:hover{
    background-color: var(--grey-110);
    border-radius: 6px;
  }

  &:focus, &:focus-visible {
    background-color: var(--grey-110);
    border: 2px solid var(--brand-primary-bright);
    outline:none;
    border-radius: 6px;
  }

}
