.p-checkbox {
  width: 22px;
  height: 22px;
  .p-checkbox-box {
    border: 2px solid #ced4da;
    background: #ffffff;
    width: 22px;
    height: 22px;
    color: #495057;
    border-radius: 4px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    .p-checkbox-icon {
      transition-duration: 0.2s;
      color: #ffffff;
      font-size: 14px;
    }
  }
  .p-checkbox-box.p-highlight {
    border-color: var(--brand-primary);
    background: var(--brand-primary);
    &:not(.p-disabled) {
      &:hover {
        border-color: var(--text-selected-item-primary);
        background: var(--text-selected-item-primary);
        color: #ffffff;
      }
    }
  }
  &:not(.p-checkbox-disabled) {
    .p-checkbox-box {
      &:hover {
        border-color: var(--brand-primary);
      }
    }
    .p-checkbox-box.p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem var(--brand-primary-bright);
      border-color: var(--brand-primary);
    }
    .p-checkbox-box.p-highlight {
      &:hover {
        border-color: var(--text-selected-item-primary);
        background: var(--text-selected-item-primary);
        color: #ffffff;
      }
    }
  }
}
.p-checkbox.p-invalid {
  >.p-checkbox-box {
    border-color: #e24c4c;
  }
}
.p-input-filled {
  .p-checkbox {
    .p-checkbox-box {
      background-color: #e9ecef;
    }
    .p-checkbox-box.p-highlight {
      background: var(--brand-primary);
    }
    &:not(.p-checkbox-disabled) {
      .p-checkbox-box {
        &:hover {
          background-color: #e9ecef;
        }
      }
      .p-checkbox-box.p-highlight {
        &:hover {
          background: var(--text-selected-item-primary);
        }
      }
    }
  }
}
