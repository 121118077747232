.p-panel {
  .p-panel-header {
    justify-content: start;
    border: 1px solid transparent;
    padding: 0;
    background: transparent;
    color: var( --text-selected-item-primary);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    .p-panel-title {
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1rem;
    }
    .p-panel-header-icon {
      //margin-left: 0.5rem;
      padding: 0 0.5rem;
      //width: 2rem;
      height: 2rem;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1rem;
      color: var( --text-selected-item-primary);
      border: 0 none;
      background: transparent;
      border-radius: 6px;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      & span {
        margin-left: 0.5rem;
      }
      &:enabled {
        &:hover {
          //color: #343a40;
          //border-color: transparent;
          //background: var(--bg-hover-item);
        }
      }
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem var(--brand-primary-bright);
      }
    }
  }
  .p-panel-content {
    padding: 0;
    border: 1px solid transparent;
    background: #ffffff;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top: 0 none;
  }
  .p-panel-footer {
    padding: 0.75rem 1.25rem;
    border: 1px solid #dee2e6;
    background: #ffffff;
    color: #495057;
    border-top: 0 none;
  }
}
.p-panel.p-panel-toggleable {
  .p-panel-header {
    padding: 0;
  }
}
