.p-tabview {
  .p-tabview-nav {
    background: #ffffff;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    li {
      margin-right: 0;
      .p-tabview-nav-link {
        border: solid #dee2e6;
        border-width: 0 0 2px 0;
        border-color: transparent transparent #dee2e6 transparent;
        background: #ffffff;
        color: #6c757d;
        padding: 1rem 1.25rem;
        font-weight: 600;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        transition: box-shadow 0.2s;
        margin: 0 0 -2px 0;
        &:not(.p-disabled) {
          &:focus {
            outline: 0 none;
            outline-offset: 0;
            //box-shadow: inset 0 0 0 0.2rem #99F6E4;
          }
        }
      }
      &:not(.p-highlight) {
        &:not(.p-disabled) {
          &:hover {
            .p-tabview-nav-link {
              background: #ffffff;
              border-color: #adb5bd;
              color: #6c757d;
            }
          }
        }
      }
    }
    li.p-highlight {
      .p-tabview-nav-link {
        background: #ffffff;
        border-color: var(--brand-primary);
        color: var(--brand-primary);
        border-width: 0 0 2px 0;
        padding-bottom: calc(1rem - 1px);
      }
    }
  }
  .p-tabview-close {
    margin-left: 0.5rem;
  }
  .p-tabview-nav-btn.p-link {
    background: #ffffff;
    color: var(--brand-primary);
    width: 3rem;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border-radius: 0;
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.2rem var(--brand-primary-bright);
    }
  }
  .p-tabview-panels {
    background: #ffffff;
    //padding: 1.5rem 0;
    padding-top: 1.5rem;
    border: 0 none;
    color: var(--text-primary);
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .p-tabview-nav li:nth-last-child(2) {
    padding-right: 40px;
  }
}
