.p-tieredmenu {
  padding: 0.25rem 0;
  background: #ffffff;
  color: var(--text-primary);
  border: 1px solid #dee2e6;
  border-radius: 6px;
  width: 12.5rem;
  .p-menuitem-link {
    padding: 0.75rem 1.25rem;
    color: var(--text-primary);
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
    .p-menuitem-text {
      color: var(--text-primary);
    }
    .p-menuitem-icon {
      color: var(--text-secondary);
      margin-right: 0.5rem;
    }
    .p-submenu-icon {
      color: var(--text-secondary);
    }
    &:not(.p-disabled) {
      &:hover {
        background: #e9ecef;
        .p-menuitem-text {
          color: var(--text-primary);
        }
        .p-menuitem-icon {
          color: var(--text-secondary);
        }
        .p-submenu-icon {
          color: var(--text-secondary);
        }
      }
    }
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 1px var(--primary-400);
    }
  }
  .p-submenu-list {
    padding: 0.25rem 0;
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
  .p-menuitem.p-menuitem-active {
    >.p-menuitem-link {
      background: var(--brand-primary-light-40);
      .p-menuitem-text {
        color: var(--text-selected-item-primary);
      }
      .p-menuitem-icon {
        color: var(--text-selected-item-primary);
      }
      .p-submenu-icon {
        color: var(--text-selected-item-primary);
      }
    }
  }
  .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.25rem 0;
  }
  .p-submenu-icon {
    font-size: 0.875rem;
  }
}
.p-tieredmenu.p-tieredmenu-overlay {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
