.p-paginator-bottom.p-paginator.p-component.ng-star-inserted{
  position: fixed !important;
  bottom: 0 !important;
  width: calc(100vw - 14%);
  height: 60px;
}

// p-table table{
//   min-height: calc(100vh - 120px) !important;
//   overflow-y: scroll;
// }
