.p-confirm-popup {
  background: #ffffff;
  color: var(--text-primary);
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  .p-confirm-popup-content {
    padding: 2rem;
  }
  .p-confirm-popup-footer {
    text-align: right;
    padding: 0 1.5rem 1.5rem 1.5rem;
    button {
      margin: 0 1rem 0 0;
      width: auto;
      &:last-child {
        margin: 0;
      }
    }
  }
  &:after {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
  }
  &:before {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
  }
  .p-confirm-popup-icon {
    font-size: 1rem;
    color: var(--text-secondary);
  }
  .p-confirm-popup-message {
    margin-left: 0.5rem;
  }
}
.p-confirm-popup.p-confirm-popup-flipped {
  &:after {
    border-top-color: #ffffff;
  }
  &:before {
    border-top-color: #ffffff;
  }
}
